// import Glide from '@glidejs/glide'
// import { changePrices } from './changePrices';
// import { lazyLoad } from './lazyload'
import { moveBackground } from './moveBackground'


// new Glide('.glide').mount()
// changePrices()
// lazyLoad()
moveBackground()
